import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { COLORS, SIZES } from "src/layout/constants"
import { Top2, Row, Column, Text } from "src/components/styled"
import { uppercase, lowercase } from "src/components/Name"

const FullwidthSection = styled.section`
  background-color: ${COLORS.light};
  padding: ${SIZES.margin}px 0;
  @media (max-width: 799px) {
    padding: ${SIZES.mobileMargin}px 0;
  }
`
const BoundRow = styled(Row)`
  max-width: ${SIZES.maxWidth}px;
  margin: 0 auto;
  h2 {
    font-size: 2.5em;
    margin: 0.4em 0;
  }
  p {
    margin: 0;
  }
  @media (min-width: 1350px) {
    h2 {
      margin: 0.7em 0;
    }
  }
`
const Limited = styled(Column)`
  min-width: 300px;
`
const Circle = styled.div`
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  display: inline-block;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 30px;
  @media (max-width: 799px) {
    margin-top: -20px;
  }
`

interface DataType {
  conception: { childImageSharp: Media }
  architecture: { childImageSharp: Media }
  research: { childImageSharp: Media }
}

const ServicesPage: GatsbyPage<DataType> = ({ data }) => {
  return (
    <Layout title="services">
      <Top2>
        <FormattedMessage id="services.title" tagName="h1" />
        <p>
          <FormattedMessage id="services.text" />
          <br />
          <br />
          <Link to="/contact/">
            <FormattedMessage id="recycled.contactLink" />
          </Link>
        </p>
      </Top2>
      <FullwidthSection>
        <BoundRow>
          <Column>
            <Image fluid={data.conception.childImageSharp.fluid} />
          </Column>
          <Limited>
            <Circle>01</Circle>
            <FormattedMessage id="services.title1" tagName="h2" />
            <FormattedMessage id="services.text1" tagName={Text} values={lowercase} />
          </Limited>
        </BoundRow>
        <BoundRow>
          <Column>
            <Image fluid={data.architecture.childImageSharp.fluid} />
          </Column>
          <Limited>
            <Circle>02</Circle>
            <FormattedMessage id="services.title2" tagName="h2" />
            <FormattedMessage id="services.text2" tagName={Text} values={lowercase} />
          </Limited>
        </BoundRow>
        <BoundRow>
          <Column>
            <Image fluid={data.research.childImageSharp.fluid} />
          </Column>
          <Limited>
            <Circle>03</Circle>
            <FormattedMessage id="services.title3" tagName="h2" />
            <FormattedMessage id="services.text3" tagName={Text} />
          </Limited>
        </BoundRow>
      </FullwidthSection>
      <Top2>
        <FormattedMessage id="services.bottomTitle" tagName="h1" />
        <p>
          <FormattedMessage id="services.bottomText" values={uppercase} />
          <br />
          <br />
          <Link to="/recycled/collaboration/">
            <FormattedMessage id="services.bottomLink" />
          </Link>
        </p>
      </Top2>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query {
    conception: file(relativePath: { eq: "services/conception.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    architecture: file(relativePath: { eq: "services/architecture.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    research: file(relativePath: { eq: "about/splash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
